(function () {
  'use strict';

  /**
   * @ngdoc service
   * @name myPizza.factory:MyPizza
   *
   * @description
   *
   */
  angular
    .module('myPizza')
    .factory('MyPizza', MyPizza);

  function MyPizza($http, consts) {
    var MyPizzaBase = {};
    MyPizzaBase.contactUs = function(text, token){
      return ({
        method: "POST",
        url: consts.serverUrl + "/", //TODO
        data: {text: text, userLoginToken : token}
      });
    }
    return MyPizzaBase;
  }
}());
